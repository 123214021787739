<template>
  <div>
    <Calender
      v-if="selectedLayer"
      ref="calender"
      :min="min"
      :max="max"
      @change="date = $event"
      @init="date = $event"
    >
      <template #day="{ day }">
        <div class="form-checkbox-wrapper">
          <b-form-checkbox
            v-for="(layer, i) in selectedLayer.configuration.layers"
            :key="`selected-${layer.name}`"
            :checked="
              hasAvailability({
                date: day.date,
                index: i,
                layer: selectedLayer.id,
              })
            "
            :class="{
              'staff-scheduling': checkStaffScheduling(day.date, i),
            }"
            :value="true"
            :disabled="day.disabled || checkStaffScheduling(day.date, i)"
            @change="value => handleFormCheckbox(day, i, value)"
          >
            <span
              v-b-tooltip.hover.top="
                `${layer.startTime} ${$t('Dates.Time.OClock')} - ${
                  layer.endTime
                } ${$t('Dates.Time.OClock')}`
              "
              :class="{
                'is-prioritized': isPrioritized({
                  date: day.date,
                  layer: selectedLayer.id,
                  index: i,
                }),
              }"
            >
              {{ layer.name }}
            </span>
          </b-form-checkbox>
        </div>
      </template>
      <template #header-right>
        <div class="d-flex align-items-center">
          <b-form-group
            :label="$t('Management.Employees.Layer')"
            label-for="layer"
          >
            <v-select
              id="layer"
              v-model="selectedLayer"
              :options="employeeLayers"
              :placeholder="
                $t('Global.Select', {
                  subject: $t('Management.Employees.Layer'),
                })
              "
              :clearable="false"
              style="min-width: 300px"
              label="area"
            >
              <template #option="{ area }">
                <div>{{ area }}</div>
              </template>
              <template #selected-option="{ area }">
                <div>{{ area }}</div>
              </template>
            </v-select>
          </b-form-group>
          <b-form-group label="‎" label-for="quickSelectionFunctions">
            <b-dropdown
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :text="
                $t(
                  'Management.Employees.QuickSelectionFunctions.QuickSelectionFunctions',
                )
              "
              variant="flat-primary"
              class="ml-2"
              no-flip
            >
              <b-dropdown-item
                v-b-tooltip.hover.top="tooltipText"
                :disabled="!hasSimiliarLayers"
                @click="copyAvailability()"
              >
                {{
                  $t(
                    'Management.Employees.QuickSelectionFunctions.CopyAvailability',
                  )
                }}
              </b-dropdown-item>
              <b-dropdown-item @click="selectEverything(selectedLayer.id)">
                {{
                  $t(
                    'Management.Employees.QuickSelectionFunctions.SelectEverything',
                  )
                }}
              </b-dropdown-item>
              <b-dropdown-item @click="deselectEverything(selectedLayer.id)">
                {{
                  $t(
                    'Management.Employees.QuickSelectionFunctions.DeselectEverything',
                  )
                }}
              </b-dropdown-item>
              <b-dropdown-item
                v-for="(layer, index) in selectedLayer.configuration.layers"
                :key="`always-${layer.name}`"
                @click="alwaysOn(selectedLayer.id, index)"
              >
                {{ $t('Management.Employees.QuickSelectionFunctions.Always') }}
                {{ layer.name }}
              </b-dropdown-item>
              <b-dropdown-item
                v-for="(layer, index) in selectedLayer.configuration.layers"
                :key="`weekends-${layer.name}`"
                @click="alwaysOnWeekends(selectedLayer.id, index)"
              >
                {{
                  $t('Management.Employees.QuickSelectionFunctions.Weekends')
                }}
                {{ layer.name }}
              </b-dropdown-item>
              <b-dropdown-item
                v-for="(layer, index) in selectedLayer.configuration.layers"
                :key="`weekdays-${layer.name}`"
                @click="alwaysOnWeekdays(selectedLayer.id, index)"
              >
                {{
                  $t('Management.Employees.QuickSelectionFunctions.Weekdays')
                }}
                {{ layer.name }}
              </b-dropdown-item>
            </b-dropdown>
          </b-form-group>
          <b-modal
            ref="multiple-layers-modal"
            :title="$t('Management.Employees.ChooseLayersForCopy')"
            :ok-title="$t('Global.Take')"
            :cancel-title="$t('Global.Retreat')"
            cancel-variant="outline-secondary"
            :ok-disabled="disabled"
            centered
            @hidden="deleteCheckBox"
            @ok="handleOk"
          >
            <b-form-checkbox
              v-for="layer in checkedSimilarLayers"
              :key="`selected-${layer.id}`"
              :checked="false"
              :value="true"
              @change="value => handleCheckboxForCopy(layer, value)"
            >
              <span>
                {{ layer.area }}
              </span>
            </b-form-checkbox>
          </b-modal>
          <b-modal
            ref="one-layer-modal"
            :title="
              $t('Management.Employees.ConfirmCopyOfOneLayer', {
                layerNameToCopy,
              })
            "
            :ok-title="$t('Global.Yes')"
            :cancel-title="$t('Global.No')"
            cancel-variant="outline-secondary"
            centered
            @hidden="deleteCheckBox"
            @ok="handleOk"
          />
          <b-modal
            ref="copy-multiple-layers-before-submit-modal"
            :title="$t('Management.Employees.ConfirmCopyBeforeSubmit')"
            :ok-title="$t('Global.Take')"
            :cancel-title="$t('Global.Retreat')"
            :ok-disabled="disabled"
            cancel-variant="outline-secondary"
            centered
            @ok="handleOkBeforeSubmit"
            @cancel="handleCancel"
            @hidden="resetModalActivity"
          >
            <b-form-checkbox
              v-for="layer in checkedSimilarLayers"
              :key="`selected-${layer.id}`"
              :checked="false"
              :value="true"
              @change="value => handleCheckboxForCopy(layer, value)"
            >
              <span>
                {{ layer.area }}
              </span>
            </b-form-checkbox>
          </b-modal>
          <b-modal
            ref="copy-one-layer-before-submit-modal"
            :title="
              $t('Management.Employees.ConfirmCopyOfOneLayer', {
                layerNameToCopy,
              })
            "
            :ok-title="$t('Global.Yes')"
            :cancel-title="$t('Global.No')"
            cancel-variant="outline-secondary"
            centered
            @cancel="handleCancel"
            @ok="handleOkBeforeSubmit"
            @hidden="resetModalActivity"
          />
        </div>
      </template>
    </Calender>
    <div v-else class="text-center mt-5">
      {{ $t('Management.Employees.NoLayer') }}
    </div>
  </div>
</template>

<script>
import {
  BFormCheckbox,
  BFormGroup,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import Calender from '@/components/Calender/Calender.vue'
import vSelect from 'vue-select'
import moment from 'moment'

export default {
  components: {
    Calender,
    BFormCheckbox,
    vSelect,
    BFormGroup,
    BDropdown,
    BDropdownItem,
  },

  props: {
    canCopyBeforeSubmit: {
      type: Boolean,
      required: true,
    },
    employee: {
      type: Object,
      required: true,
    },
    availabilities: {
      type: Array,
      required: true,
    },
    min: {
      type: Object,
      required: true,
    },
    max: {
      type: Object,
      required: true,
    },
    activeTab: {
      type: Number,
      required: true,
    },
  },

  data: () => ({
    selectedLayer: null,
    date: null,
    similarLayers: [],
    checkedSimilarLayers: [],
    staffScheduling: [],
    checkBoxSet: new Set(),
    staffSchedulingCache: {},
    disabled: true,
    layerNameToCopy: null,
  }),

  computed: {
    tooltipText() {
      return this.hasSimiliarLayers
        ? ''
        : this.$t('Management.Employees.NoMatchingLayerAvailable')
    },
    employeeLayers() {
      return this.employee.layers.filter(x => x.active === true)
    },
    employeeLayersLength() {
      return this.employeeLayers.length
    },
    hasSimiliarLayers() {
      const similarLayers = []
      this.checkShifts(layer => similarLayers.push(layer))
      return similarLayers.length > 0
    },
  },

  watch: {
    activeTab() {
      this.similarLayers = []
    },
    date() {
      this.loadStaffScheduling()
    },
    employeeLayersLength() {
      this.setSelectedLayers()
    },
    canCopyBeforeSubmit(value) {
      if (value) {
        this.openModalBeforeSubmit()
      }
    },
  },

  mounted() {
    this.setSelectedLayers()
  },

  methods: {
    calculateSimilarLayer() {
      this.similarLayers = []
      this.checkShifts(layer => this.similarLayers.push(layer))
    },

    checkShifts(callback) {
      this.employeeLayers.forEach(layer => {
        if (
          layer.configuration.layers.length ===
            this.selectedLayer.configuration.layers.length &&
          layer !== this.selectedLayer
        ) {
          const add = layer.configuration.layers.every(
            (shift, index) =>
              shift.name ===
                this.selectedLayer.configuration.layers[index].name &&
              shift.startTime ===
                this.selectedLayer.configuration.layers[index].startTime &&
              shift.endTime ===
                this.selectedLayer.configuration.layers[index].endTime,
          )
          if (add) {
            callback(layer)
          }
        }
      })
    },

    deleteCheckBox() {
      this.checkBoxSet.clear()
    },
    openModalBeforeSubmit() {
      this.calculateSimilarLayer()
      this.checkedSimilarLayers = []

      if (!this.hasSimiliarLayers) {
        this.$emit('submitAfterModal')
        return
      }

      this.similarLayers.forEach(layer => {
        if (!this.compareTwoSimiliarLayers(layer, this.selectedLayer)) {
          this.checkedSimilarLayers.push(layer)
        }
      })

      if (this.checkedSimilarLayers.length > 1) {
        this.$refs['copy-multiple-layers-before-submit-modal'].toggle()
        return
      }
      if (this.checkedSimilarLayers.length === 1) {
        this.layerNameToCopy = this.checkedSimilarLayers[0].area
        this.checkBoxSet.add(this.checkedSimilarLayers[0])
        this.$refs['copy-one-layer-before-submit-modal'].toggle()
        return
      }
      this.$emit('submitAfterModal')
    },
    checkStaffScheduling(date, layerIndex) {
      return Boolean(
        this.staffScheduling.find(
          x =>
            x.date === date &&
            this.selectedLayer.id === x.layer &&
            x.layerIndex === layerIndex,
        ),
      )
    },

    checkStaffSchedulingForOtherLayers(date, layerIndex, layerId) {
      return Boolean(
        this.staffScheduling.find(
          x =>
            x.date === date &&
            x.layer === layerId &&
            x.layerIndex === layerIndex,
        ),
      )
    },

    async loadStaffScheduling() {
      try {
        const date = moment(this.date)
        const year = date.year()
        const month = date.month()

        if (this.staffSchedulingCache[year] === undefined) {
          this.staffSchedulingCache[year] = {}
        }

        if (this.staffSchedulingCache[year][month] !== undefined) {
          this.staffScheduling = this.staffSchedulingCache[year][month]
          return
        }

        const { data } = await this.$axios.get(
          `staff-scheduling/employee/for-month/${this.employee.id}/${year}/${month}`,
        )

        this.staffSchedulingCache[year][month] = data
        this.staffScheduling = data
      } catch {
        this.$alert.error()
      }
    },
    handleOkBeforeSubmit() {
      this.handleOk()
      this.$emit('submitAfterModal')
    },
    handleCancel() {
      this.$emit('submitAfterModal')
    },
    resetModalActivity() {
      this.$emit('resetModalActivity')
    },
    handleOk() {
      let copyOfAvailabilities = [...this.availabilities]
      copyOfAvailabilities = copyOfAvailabilities.filter(x => {
        let keepAvailability = true
        const momentDate = moment(x.date, 'YYYY-MM-DD')
        this.checkBoxSet.forEach(layer => {
          if (x.layer === layer.id && momentDate.isAfter(this.min)) {
            keepAvailability = false
          }
        })
        return keepAvailability
      })
      const currentLayerAvailbilities = copyOfAvailabilities.filter(
        x => x.layer === this.selectedLayer.id,
      )
      const filteredAvailbilities = currentLayerAvailbilities.filter(x => {
        const momentDate = moment(x.date, 'YYYY-MM-DD')
        const dateInvalid =
          momentDate.isBefore(this.min) || momentDate.isAfter(this.max)
        if (dateInvalid || this.checkStaffScheduling(x.date, x.index)) {
          return false
        }

        return true
      })
      filteredAvailbilities.forEach(availability => {
        this.checkBoxSet.forEach(layer => {
          if (
            !this.checkStaffSchedulingForOtherLayers(
              availability.date,
              availability.index,
              layer.id,
            )
          ) {
            copyOfAvailabilities.push({
              date: availability.date,
              employee: this.employee.id,
              layer: layer.id,
              index: availability.index,
            })
          }
        })
      })
      this.disabled = true
      this.checkBoxSet.clear()
      this.$emit('setAvailabilities', copyOfAvailabilities)
    },

    handleCheckboxForCopy(layer, value) {
      if (value) {
        this.checkBoxSet.add(layer)
        this.disabled = this.checkBoxSet.size <= 0
        return
      }
      if (this.checkBoxSet.has(layer)) {
        this.checkBoxSet.delete(layer)
      }
      this.disabled = this.checkBoxSet.size <= 0
    },

    copyAvailability() {
      this.calculateSimilarLayer()
      this.checkedSimilarLayers = []
      this.checkBoxSet.clear()
      this.similarLayers.forEach(layer => {
        if (!this.compareTwoSimiliarLayers(layer, this.selectedLayer)) {
          this.checkedSimilarLayers.push(layer)
        }
      })
      if (this.checkedSimilarLayers.length === 0) {
        this.$alert.success(
          this.$t('Management.Employees.LayersHaveSameAvailabilities', {
            layer1: this.selectedLayer.area,
            layer2: this.similarLayers.map(layer => layer.area),
          }),
        )
        return
      }
      if (this.checkedSimilarLayers.length === 1) {
        this.checkBoxSet.add(this.checkedSimilarLayers[0])
        this.layerNameToCopy = this.checkedSimilarLayers[0].area
        this.$refs['one-layer-modal'].toggle()
        return
      }
      this.$refs['multiple-layers-modal'].toggle()
    },

    selectEverything(layer) {
      const layersCount = this.selectedLayer.configuration.layers.length
      this.$refs.calender.getDaysByMonth.forEach(date => {
        for (let i = 0; i < layersCount; i += 1) {
          const momentDate = moment(date, 'YYYY-MM-DD')
          const dateInvalid =
            momentDate.isBefore(this.min) || momentDate.isAfter(this.max)
          if (
            !dateInvalid &&
            !this.checkStaffScheduling(date, i) &&
            !this.hasAvailability({ date, index: i, layer })
          ) {
            this.addAvailability({ date }, i)
          }
        }
      })
    },
    deselectEverything(layer) {
      this.$emit(
        'setAvailabilities',
        this.availabilities.filter(x => {
          const momentDate = moment(x.date, 'YYYY-MM-DD')
          const dateInvalid =
            momentDate.isBefore(this.min) || momentDate.isAfter(this.max)

          if (dateInvalid || this.checkStaffScheduling(x.date, x.index)) {
            return true
          }

          return x.layer !== layer
        }),
      )
    },
    alwaysOn(layer, index) {
      this.$refs.calender.getDaysByMonth.forEach(date => {
        const momentDate = moment(date, 'YYYY-MM-DD')
        const dateInvalid =
          momentDate.isBefore(this.min) || momentDate.isAfter(this.max)
        if (
          !dateInvalid &&
          !this.checkStaffScheduling(date, index) &&
          !this.hasAvailability({ date, index, layer })
        ) {
          this.addAvailability({ date }, index)
        }
      })
    },
    alwaysOnWeekends(layer, index) {
      this.$refs.calender.getDaysByMonth.forEach(date => {
        const weekday = moment(date, 'YYYY-MM-DD').weekday()
        const isWeekend = weekday === 5 || weekday === 6
        const momentDate = moment(date, 'YYYY-MM-DD')
        const dateInvalid =
          momentDate.isBefore(this.min) || momentDate.isAfter(this.max)
        if (
          isWeekend &&
          !dateInvalid &&
          !this.checkStaffScheduling(date, index) &&
          !this.hasAvailability({ date, index, layer })
        ) {
          this.addAvailability({ date }, index)
        }
      })
    },
    alwaysOnWeekdays(layer, index) {
      this.$refs.calender.getDaysByMonth.forEach(date => {
        const weekday = moment(date, 'YYYY-MM-DD').weekday()
        const isWeekday = weekday !== 5 && weekday !== 6
        const momentDate = moment(date, 'YYYY-MM-DD')
        const dateInvalid =
          momentDate.isBefore(this.min) || momentDate.isAfter(this.max)
        if (
          isWeekday &&
          !dateInvalid &&
          !this.checkStaffScheduling(date, index) &&
          !this.hasAvailability({ date, index, layer })
        ) {
          this.addAvailability({ date }, index)
        }
      })
    },

    setSelectedLayers() {
      if (this.employeeLayers.length > 0) {
        const [layer] = this.employeeLayers
        this.selectedLayer = layer
        return
      }

      this.selectedLayer = null
    },

    handleFormCheckbox(day, index, value) {
      if (value) {
        this.addAvailability(day, index)
        return
      }
      this.removeAvailability(day, index)
    },

    hasAvailability({ date, index, layer }) {
      return Boolean(
        this.availabilities.find(
          x => x.layer === layer && x.index === index && x.date === date,
        ),
      )
    },

    isPrioritized({ date, layer, index }) {
      const av = this.availabilities.find(
        x => x.layer === layer && x.index === index && x.date === date,
      )

      if (!av) return false

      return av.isPrioritized
    },

    removeAvailability(day, i) {
      this.$emit('dirty')
      const index = this.availabilities.findIndex(x => {
        if (x.layer !== this.selectedLayer.id) return false
        if (x.date !== day.date) return false
        if (x.index !== i) return false
        return true
      })

      if (index > -1) {
        this.availabilities.splice(index, 1)
      }
    },
    addAvailability(day, index) {
      this.$emit('dirty')
      this.availabilities.push({
        date: day.date,
        employee: this.employee.id,
        layer: this.selectedLayer.id,
        index,
      })
    },

    compareTwoSimiliarLayers(layer1, layer2) {
      const isValidDate = x => {
        const momentDate = moment(x.date, 'YYYY-MM-DD')
        const dateInvalid =
          momentDate.isBefore(this.min) || momentDate.isAfter(this.max)
        return !dateInvalid
      }

      const availabilitiesOfLayer1 = this.availabilities.filter(
        x => x.layer === layer1.id && isValidDate(x),
      )

      const availabilitiesOfLayer2 = this.availabilities.filter(
        x => x.layer === layer2.id && isValidDate(x),
      )

      for (let i = 0; i < availabilitiesOfLayer2.length; i += 1) {
        const result = availabilitiesOfLayer1.find(
          x =>
            x.date === availabilitiesOfLayer2[i].date &&
            x.index === availabilitiesOfLayer2[i].index,
        )

        if (result === undefined) {
          if (
            !this.checkStaffSchedulingForOtherLayers(
              availabilitiesOfLayer2[i].date,
              availabilitiesOfLayer2[i].index,
              layer1.id,
            )
          ) {
            return false
          }
        }
      }

      for (let i = 0; i < availabilitiesOfLayer1.length; i += 1) {
        const result = availabilitiesOfLayer2.find(
          x =>
            x.date === availabilitiesOfLayer1[i].date &&
            x.index === availabilitiesOfLayer1[i].index,
        )
        if (result === undefined) {
          return false
        }
      }
      return true
    },
  },
}
</script>

<style lang="scss" scoped>
.form-checkbox-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-block: 0.5rem;
}

.staff-scheduling {
  .custom-control-label span {
    color: var(--danger) !important;
  }
}

.is-prioritized {
  text-decoration: underline #ffa500;
  text-underline-offset: 4px;
}
</style>

import moment from 'moment'
import { ref, computed, watch } from '@vue/composition-api'

moment.locale('de')

const CALENDER_ITEMS = 42

const useCalender = ({ min, max }, { emit }) => {
  const date = ref(new Date().getTime())
  emit('change', date.value)

  watch(date, v => emit('change', v))

  const populateGrid = grid =>
    grid.map(x => {
      const momentDate = moment(x, 'YYYY-MM-DD')
      return {
        date: x,
        day: momentDate.date(),
        active: momentDate.isSame(date.value, 'month'),
        disabled: momentDate.isBefore(min) || momentDate.isAfter(max),
      }
    })

  const nextMonth = () => {
    date.value += moment(date.value).daysInMonth() * 24 * 60 * 60 * 1000
  }

  const prevMonth = () => {
    date.value -= moment(date.value).daysInMonth() * 24 * 60 * 60 * 1000
  }

  const dateLong = computed(() => moment(date.value).format('MMMM YYYY'))

  const getDaysByMonth = computed(() => {
    let daysInMonth = moment(date.value).daysInMonth()
    const days = []

    while (daysInMonth) {
      const current = moment(date.value).date(daysInMonth).format('YYYY-MM-DD')
      days.push(current)
      daysInMonth -= 1
    }

    return days.reverse()
  })

  const calenderGrid = computed(() => {
    const grid = [...getDaysByMonth.value]

    const firstOfMonth = moment(grid[0], 'YYYY-MM-DD')
    const lastOfMonth = moment(grid[grid.length - 1], 'YYYY-MM-DD')

    for (let i = 0; i < firstOfMonth.weekday(); i += 1) {
      grid.unshift(
        firstOfMonth
          .clone()
          .subtract(i + 1, 'days')
          .format('YYYY-MM-DD'),
      )
    }

    while (grid.length < CALENDER_ITEMS) {
      grid.push(lastOfMonth.add(1, 'days').format('YYYY-MM-DD'))
    }

    return populateGrid(grid)
  })

  return {
    getDaysByMonth,
    calenderGrid,
    date,
    dateLong,
    nextMonth,
    prevMonth,
  }
}

export default useCalender

<template>
  <div class="calender">
    <div class="calender-header">
      <div class="calender-headline">
        <feather-icon icon="ChevronLeftIcon" size="24" @click="prevMonth" />
        <feather-icon icon="ChevronRightIcon" size="24" @click="nextMonth" />
        <h4>{{ dateLong }}</h4>
      </div>
      <slot name="header-right" />
    </div>
    <div class="calender-base">
      <div class="calender-top">
        <h6
          v-for="weekDay in [
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
            'Sunday',
          ]"
          :key="weekDay"
        >
          {{ $t(`Dates.Week.Long.${weekDay}`) }}
        </h6>
      </div>
      <div class="calender-grid">
        <div
          v-for="day in calenderGrid"
          :key="day.date"
          :class="{
            inactive: !day.active,
          }"
          class="calender-item"
        >
          <div class="calender-day">
            {{ day.day }}
          </div>
          <div class="calender-slot">
            <slot name="day" :day="day">
              {{ day }}
            </slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useCalender from './useCalender'

export default {
  name: 'CustomCalender',

  props: {
    min: {
      type: Object,
      default: () => null,
    },
    max: {
      type: Object,
      default: () => null,
    },
  },

  emits: ['change', 'init'],

  setup({ min, max }, context) {
    const { nextMonth, prevMonth, calenderGrid, dateLong, getDaysByMonth } =
      useCalender({ min, max }, context)

    return {
      nextMonth,
      calenderGrid,
      prevMonth,
      dateLong,
      getDaysByMonth,
    }
  },
}
</script>

<style lang="scss" scoped>
@import './index';
</style>

<template>
  <b-overlay :show="isLoading">
    <b-card v-if="employee" class="p-2">
      <validation-observer ref="rules" tag="form" @submit.prevent="submit">
        <b-row class="mb-4">
          <b-col lg="12" md="12" sm="12" class="d-flex align-items-center">
            <b-avatar
              size="80"
              variant="light-primary"
              class="badge-minimal"
              :text="nameAbbreviation"
            />
            <div class="ml-2">
              <span>&lrm;</span>
              <h1 class="font-weight-bolder my-0">
                {{ employee.firstname }} {{ employee.lastname }}
              </h1>
              <span
                :style="{
                  color: `var(--${employee.active ? 'success' : 'danger'})`,
                }"
              >
                {{
                  $t(
                    `Management.Employees.${
                      employee.active ? 'Active' : 'Inactive'
                    }`,
                  )
                }}
              </span>
            </div>
          </b-col>
        </b-row>
        <b-tabs v-model="activeTab">
          <b-tab :title="$t('Management.Employees.BaseData')">
            <BaseData
              :employee="employee"
              :name-abbreviation="nameAbbreviation"
              :show-short-term-employment-days="showShortTermEmploymentDays"
              :layers="layers"
              @dirty="dirty = true"
            />
          </b-tab>
          <b-tab :title="$t('Management.Employees.Availability')">
            <Availability
              :employee="employee"
              :availabilities="availabilities"
              :can-copy-before-submit="canCopyBeforeSubmit"
              :active-tab="activeTab"
              :min="min"
              :max="max"
              @setAvailabilities="setAvailabilities"
              @dirty="dirty = true"
              @submitAfterModal="submitAfterModal"
              @resetModalActivity="resetModalActivity"
            />
          </b-tab>
        </b-tabs>
        <b-row class="mt-5">
          <b-button
            v-if="$can($acl.action.Update, $acl.subjects.Employees)"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            type="submit"
          >
            {{ $t('Global.Save') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            :class="{
              'ml-3': $can($acl.action.Update, $acl.subjects.Employees),
            }"
            @click="$router.push({ name: 'employees' })"
          >
            {{ $t('Global.Retreat') }}
          </b-button>
        </b-row>
      </validation-observer>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BAvatar,
  BCard,
  BRow,
  BCol,
  BButton,
  BTabs,
  BTab,
  BOverlay,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import moment from 'moment'

import { preventUnsavedChangesDataLevel } from '@/utility/mixins/preventUnsavedChanges'
import compareArray from '@/utility/functions/compareArrays'

import Contracts from './utility/contracts'
import BaseData from './components/baseData.vue'
import Availability from './components/availability.vue'
import removeLayersPopup from './utility/removeLayersPopup'

export default {
  components: {
    BAvatar,
    BCard,
    BRow,
    BCol,
    BButton,
    BTabs,
    BTab,
    BOverlay,
    ValidationObserver,
    BaseData,
    Availability,
  },

  mixins: [preventUnsavedChangesDataLevel],

  data: () => ({
    activeTab: 0,
    dirty: false,
    employee: null,
    layers: [],
    availabilities: [],
    employeeOldLayers: [],
    isLoading: false,
    canCopyBeforeSubmit: false,
  }),

  computed: {
    showShortTermEmploymentDays() {
      return this.employee.contract === Contracts.shortTermEmployment
    },
    nameAbbreviation() {
      if (!this.employee) return ''
      return (
        this.employee.firstname.charAt(0) + this.employee.lastname.charAt(0)
      )
    },
    min() {
      return moment().subtract(1, 'day')
    },
    max() {
      return moment().add(1, 'year')
    },
  },

  async created() {
    this.isLoading = true
    try {
      const [employees, layers, availabilities] = await Promise.all([
        this.$axios.get(`employees/${this.$route.params.id}`),
        this.$axios.get('layers'),
        this.$axios.get(`availabilities/employee/${this.$route.params.id}`),
      ])
      this.employee = employees.data
      this.employeeOldLayers = JSON.parse(JSON.stringify(employees.data.layers))
      this.layers = layers.data
      this.availabilities = availabilities.data
    } catch (error) {
      this.$alert.error()
      this.$router.push({ name: 'employees' })
    }
    this.isLoading = false
  },

  methods: {
    setAvailabilities(availabilities) {
      this.availabilities = availabilities
    },

    resetModalActivity() {
      this.canCopyBeforeSubmit = false
    },

    submit() {
      if (this.activeTab === 1) {
        this.canCopyBeforeSubmit = true
        return
      }
      this.submitAfterModal()
    },

    async submitAfterModal() {
      const valid = await this.$refs.rules.validate()
      this.isLoading = true

      if (!valid) {
        this.$alert.invalid()
        this.isLoading = false
        return
      }

      try {
        const parsedShortTermEmploymentDays = Object.keys(
          this.employee.shortTermEmploymentDays,
        ).reduce((prev, curr) => {
          const value = this.employee.shortTermEmploymentDays[curr]

          if (typeof value === 'number') {
            prev[curr] = value
            return prev
          }

          prev[curr] = parseInt(value, 10)
          return prev
        }, {})

        const employeeBody = {
          shortTermEmploymentDays: parsedShortTermEmploymentDays,
          layers: this.employee.layers.map(x => x.id),
        }

        const { removedElements } = compareArray(
          this.employeeOldLayers.map(x => x.id),
          employeeBody.layers,
        )

        if (removedElements.length !== 0) {
          const checkLayers = await this.$axios.post(
            'staff-scheduling/employee/layer-remove-impact',
            {
              employee: this.employee.id,
              layers: removedElements,
            },
          )

          if (checkLayers.data !== 0) {
            const removeLayersRegardless = await removeLayersPopup.call(
              this,
              checkLayers.data,
            )

            if (!removeLayersRegardless) {
              this.employee.layers = this.employeeOldLayers
              this.isLoading = false
              return
            }
          }
        }

        const [employee] = await Promise.all([
          this.$axios.patch(`employees/${this.employee.id}`, employeeBody),
          this.$axios.post('staff-scheduling/employee/layer-remove', {
            employee: this.employee.id,
            layers: removedElements,
          }),
          this.$axios.patch(
            `availabilities/employee/${this.employee.id}`,
            this.availabilities.filter(x => {
              const momentDate = moment(x.date, 'YYYY-MM-DD')
              const dateInvalid =
                momentDate.isBefore(this.min) || momentDate.isAfter(this.max)
              if (dateInvalid) return true
              return this.employee.layers.map(y => y.id).includes(x.layer)
            }),
          ),
        ])

        this.$alert.update(
          `${employee.data.firstname} ${employee.data.lastname}`,
        )
        this.dirty = false
        this.$router.push({ name: 'employees' })
      } catch {
        this.$alert.error()
      }
      this.isLoading = false
    },
  },
}
</script>

<template>
  <b-row>
    <b-col lg="4" md="4" sm="12">
      <b-form-group :label="$t('Management.Employees.Username')">
        <b-form-input :value="username" :disabled="true" />
      </b-form-group>
    </b-col>
    <b-col lg="4" md="4" sm="12">
      <b-form-group :label="$t('Management.Employees.Firstname')">
        <b-form-input :value="employee.firstname" :disabled="true" />
      </b-form-group>
    </b-col>
    <b-col lg="4" md="4" sm="12">
      <b-form-group :label="$t('Management.Employees.Lastname')">
        <b-form-input :value="employee.lastname" :disabled="true" />
      </b-form-group>
    </b-col>
    <b-col lg="4" md="4" sm="12">
      <b-form-group :label="$t('Management.Employees.EmployeeNumber')">
        <b-form-input :value="employee.employeeNumber" :disabled="true" />
      </b-form-group>
    </b-col>
    <b-col lg="4" md="4" sm="12">
      <b-form-group :label="$t('Management.Employees.Email')">
        <b-form-input :value="employee.email" :disabled="true" />
      </b-form-group>
    </b-col>
    <b-col lg="4" md="4" sm="12">
      <b-form-group :label="$t('Management.Employees.Phone')">
        <b-form-input :value="employee.phone" :disabled="true" />
      </b-form-group>
    </b-col>
    <b-col lg="4" md="4" sm="12">
      <b-form-group :label="$t('Management.Employees.CostCentre')">
        <b-form-input :value="employee.costCentre" :disabled="true" />
      </b-form-group>
    </b-col>
    <b-col lg="4" md="4" sm="12">
      <b-form-group :label="$t('Management.Employees.Contract.Contract')">
        <b-form-input
          :value="
            this.$t(`Management.Employees.Contract.Types.${employee.contract}`)
          "
          :disabled="true"
        />
      </b-form-group>
    </b-col>
    <b-col v-if="showShortTermEmploymentDays" lg="4" md="4" sm="12">
      <b-form-group
        :label="$t('Management.Employees.ShortTermEmploymentDaysTerms')"
        label-for="shortTermEmploymentDays"
      >
        <validation-provider
          #default="{ errors }"
          :name="$t('Management.Employees.ShortTermEmploymentDays')"
          rules="integer"
        >
          <b-form-input
            id="shortTermEmploymentDays"
            v-model="employee.shortTermEmploymentDays[currentYear]"
            type="tel"
            :placeholder="
              $t('Global.Write', {
                subject: $t('Management.Employees.ShortTermEmploymentDays'),
              })
            "
            :state="errors.length > 0 ? false : null"
            autocomplete="off"
            @input="$emit('dirty')"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col lg="4" md="4" sm="12">
      <b-form-group :label="$t('Management.Employees.HourlyWage')">
        <b-form-input :value="employee.hourlyWage" :disabled="true" />
      </b-form-group>
    </b-col>
    <b-col lg="4" md="4" sm="12">
      <b-form-group
        :label="$t('Management.Employees.ResponsibleLayers')"
        label-for="responsibleLayers"
      >
        <v-select
          id="responsibleLayers"
          v-model="employee.layers"
          :options="layers"
          :multiple="true"
          :placeholder="
            $t('Global.Select', {
              subject: $t('Management.Employees.ResponsibleLayers'),
            })
          "
          label="area"
          @input="$emit('dirty')"
        >
          <template #option="{ area, active }">
            <div class="d-flex align-items-center">
              <feather-icon
                v-if="!active"
                v-b-tooltip.hover.top="$t('Management.Employees.InactiveLayer')"
                icon="LockIcon"
                style="margin-right: 0.25rem"
              />
              <span style="margin-top: 0.15rem">{{ area }}</span>
            </div>
          </template>
          <template #selected-option="{ area, active }">
            <div class="d-flex align-items-center">
              <feather-icon
                v-if="!active"
                v-b-tooltip.hover.top="$t('Management.Employees.InactiveLayer')"
                icon="LockIcon"
                style="margin-right: 0.25rem"
              />
              <span style="margin-top: 0.15rem">{{ area }}</span>
            </div>
          </template>
        </v-select>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { BFormInput, BRow, BCol, BFormGroup } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import { required, email, integer } from '@validations'

import { preventUnsavedChangesRouteLevel } from '@/utility/mixins/preventUnsavedChanges'

export default {
  components: {
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    ValidationProvider,
    vSelect,
  },

  mixins: [preventUnsavedChangesRouteLevel],

  props: {
    employee: {
      type: Object,
      required: true,
    },
    nameAbbreviation: {
      type: String,
      required: true,
    },
    showShortTermEmploymentDays: {
      type: Boolean,
      required: true,
    },
    layers: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    required,
    email,
    integer,
  }),

  computed: {
    currentYear() {
      const now = new Date()
      return now.getFullYear()
    },
    username() {
      if (!this.employee) return ''
      return this.nameAbbreviation + this.employee.employeeNumber
    },
  },
}
</script>
